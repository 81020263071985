export const languageOptions = [
  { value: 'English', label: 'English' },
  { value: 'French', label: 'French' },
  { value: 'German', label: 'German' },
  { value: 'Luxembourgish', label: 'Luxembourgish' },
  { value: 'Spanish', label: 'Spanish' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Portugese', label: 'Portugese' },
  { value: 'Dutch', label: 'Dutch' },
  { value: 'Akan', label: 'Akan' },
  { value: 'Amharic', label: 'Amharic' },
  { value: 'Arabic', label: 'Arabic' },
  { value: 'Assamese', label: 'Assamese' },
  { value: 'Awadhi', label: 'Awadhi' },
  { value: 'Azerbaijani', label: 'Azerbaijani' },
  { value: 'Balochi', label: 'Balochi' },
  { value: 'Belarusian', label: 'Belarusian' },
  { value: 'Bengali', label: 'Bengali' },
  { value: 'Bhojpuri', label: 'Bhojpuri' },
  { value: 'Burmese', label: 'Burmese' },
  { value: 'Cebuano (Visayan)', label: 'Cebuano (Visayan)' },
  { value: 'Chewa', label: 'Chewa' },
  { value: 'Chhattisgarhi', label: 'Chhattisgarhi' },
  { value: 'Chittagonian', label: 'Chittagonian' },
  { value: 'Czech', label: 'Czech' },
  { value: 'Deccan', label: 'Deccan' },
  { value: 'Dhundhari', label: 'Dhundhari' },
  { value: 'Eastern Min', label: 'Eastern Min' },
  { value: 'Fula', label: 'Fula' },
  { value: 'Gan Chinese', label: 'Gan Chinese' },
  { value: 'Greek', label: 'Greek' },
  { value: 'Gujarati', label: 'Gujarati' },
  { value: 'Haitian Creole', label: 'Haitian Creole' },
  { value: 'Hakka', label: 'Hakka' },
  { value: 'Haryanvi', label: 'Haryanvi' },
  { value: 'Hausa', label: 'Hausa' },
  { value: 'Hiligaynon', label: 'Hiligaynon' },
  { value: 'Hindi', label: 'Hindi' },
  { value: 'Hmong', label: 'Hmong' },
  { value: 'Hungarian', label: 'Hungarian' },
  { value: 'Igbo', label: 'Igbo' },
  { value: 'Ilocano', label: 'Ilocano' },
  { value: 'Italian', label: 'Italian' },
  { value: 'Japanese', label: 'Japanese' },
  { value: 'Javanese', label: 'Javanese' },
  { value: 'Jin', label: 'Jin' },
  { value: 'Kannada', label: 'Kannada' },
  { value: 'Kazakh', label: 'Kazakh' },
  { value: 'Khmer', label: 'Khmer' },
  { value: 'Kinyarwanda', label: 'Kinyarwanda' },
  { value: 'Kirundi', label: 'Kirundi' },
  { value: 'Konkani', label: 'Konkani' },
  { value: 'Korean', label: 'Korean' },
  { value: 'Kurdish', label: 'Kurdish' },
  { value: 'Madurese', label: 'Madurese' },
  { value: 'Magahi', label: 'Magahi' },
  { value: 'Maithili', label: 'Maithili' },
  { value: 'Malagasy', label: 'Malagasy' },
  { value: 'Malay/Indonesian', label: 'Malay/Indonesian' },
  { value: 'Malayalam', label: 'Malayalam' },
  { value: 'Mandarin', label: 'Mandarin' },
  { value: 'Marathi', label: 'Marathi' },
  { value: 'Marwari', label: 'Marwari' },
  { value: 'Mossi', label: 'Mossi' },
  { value: 'Nepali', label: 'Nepali' },
  { value: 'Northern Min', label: 'Northern Min' },
  { value: 'Odia (Oriya)', label: 'Odia (Oriya)' },
  { value: 'Oromo', label: 'Oromo' },
  { value: 'Pashto', label: 'Pashto' },
  { value: 'Persian', label: 'Persian' },
  { value: 'Polish', label: 'Polish' },
  { value: 'Punjabi', label: 'Punjabi' },
  { value: 'Quechua', label: 'Quechua' },
  { value: 'Romanian', label: 'Romanian' },
  { value: 'Russian', label: 'Russian' },
  { value: 'Saraiki', label: 'Saraiki' },
  { value: 'Serbo-Croatian', label: 'Serbo-Croatian' },
  { value: 'Shona', label: 'Shona' },
  { value: 'Sindhi', label: 'Sindhi' },
  { value: 'Sinhalese', label: 'Sinhalese' },
  { value: 'Somali', label: 'Somali' },
  { value: 'Southern Min', label: 'Southern Min' },
  { value: 'Sundanese', label: 'Sundanese' },
  { value: 'Swedish', label: 'Swedish' },
  { value: 'Sylheti', label: 'Sylheti' },
  { value: 'Tagalog', label: 'Tagalog' },
  { value: 'Tamil', label: 'Tamil' },
  { value: 'Telugu', label: 'Telugu' },
  { value: 'Thai', label: 'Thai' },
  { value: 'Turkish', label: 'Turkish' },
  { value: 'Turkmen', label: 'Turkmen' },
  { value: 'Ukrainian', label: 'Ukrainian' },
  { value: 'Urdu', label: 'Urdu' },
  { value: 'Uyghur', label: 'Uyghur' },
  { value: 'Uzbek', label: 'Uzbek' },
  { value: 'Vietnamese', label: 'Vietnamese' },
  { value: 'Wu (inc. Shanghainese)', label: 'Wu (inc. Shanghainese)' },
  { value: 'Xhosa', label: 'Xhosa' },
  { value: 'Xiang (Hunnanese)', label: 'Xiang (Hunnanese)' },
  { value: 'Yoruba', label: 'Yoruba' },
  { value: 'Yue (Cantonese)', label: 'Yue (Cantonese)' },
  { value: 'Zhuang', label: 'Zhuang' },
  { value: 'Zulu', label: 'Zulu' },
]

export const offered_services_options = [
  {
    value: 'overnight_sitter_home',
    label: 'Overnight Boarding Sitter`s Home',
  },
  {
    value: 'overnight_parent_home',
    label: 'Overnight Boarding Dog Parent`s Home',
  },
  { value: 'daycare_sitter_home', label: 'Daycare Sitter`s Home' },
  { value: 'daycare_parent_home', label: 'Daycare Dog Parent`s Home' },
]

export const overnight_offered_services_options = [
  { value: 'overnight_sitter_home', label: 'Overnight Boarding Sitter`s Home' },
  { value: 'overnight_parent_home', label: 'Overnight Boarding Dog Parent`s Home' }
]

export const daycare_offered_services_options = [
  { value: 'daycare_sitter_home', label: 'Daycare Sitter`s Home' },
  { value: 'daycare_parent_home', label: 'Daycare Dog Parent`s Home' },
]

export const size_of_dog_options = [
  { value: 'small', label: 'Small (less than 14 kilos)' },
  { value: 'medium', label: 'Medium (between 14 and 25 kilos)' },
  { value: 'large', label: 'Large (more than 25 kilos)' },
  { value: 'all_size', label: 'All sizes OK' },
]

export const outdoor_spaces_options = [
  { value: 'Terrace', label: 'Terrace' },
  { value: 'Garden', label: 'Garden' },
  { value: 'Balcony', label: 'Balcony' },
  { value: 'Yard', label: 'Yard' },
  { value: 'No green space', label: 'None' }
]

export const nearby_places_options = [
  { value: 'Park', label: 'Park' },
  { value: 'Forest', label: 'Forest' },
  { value: 'Hiking trail', label: 'Hiking trail' },
  { value: 'Dog Park', label: 'Dog Park' },
  { value: 'Walking Path', label: 'Walking Path' },
  { value: 'No green space', label: 'None' }
]

export const age_of_dog_options = [
  { value: 'young_puppy', label: 'Very Young Puppy (less than 1 year old)' },
  { value: 'puppy', label: 'Puppy (less than 2 years old)' },
  { value: 'adult_dog', label: 'Adult dog (over 2 years old)' },
  { value: 'senior_dog', label: 'Senior dog (above 7 years old)' },
  { value: 'all_age', label: 'All ages welcome' },
]