import { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { DashboardRoutes } from 'Views/Dashboard/Constants/routes'

const SidebarContent = () => {
  return (
    <Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          {DashboardRoutes.map(
            ({ id, routePath, name, icon, exact, subRoutes }) => (
              <li key={id}>
                {/* @ts-ignore */}
                <Link
                  {...(!exact && subRoutes && !routePath
                    ? {}
                    : { to: routePath })}
                  className={`${
                    !exact && subRoutes ? 'has-arrow ' : ''
                  }waves-effect`}
                >
                  <i className={icon}></i>
                  <span className="ml-1">{name}</span>
                </Link>
                {!exact && subRoutes && (
                  <ul className="sub-menu">
                    {subRoutes.map(
                      ({ routePath, name }, i) =>
                        routePath &&
                        name && (
                          <li key={i}>
                            <Link style={(name.includes(' (') && !name.includes('(0)')) ? { backgroundColor: '#e1e9f0' } : {}}
                              to={routePath}>{name}</Link>
                          </li>
                        )
                    )}
                  </ul>
                )}
              </li>
            )
          )}
        </ul>
      </div>
    </Fragment>
  )
}

export default SidebarContent
