import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Input, Label } from 'reactstrap'
import Table from '../Table'
import { userRole } from 'Utils/helper'

const BookingFilteredSitterList = ({
  tableData = [],
  tableHeading = [],
  handleTablePageChange,
  handleViewSitterData,
  handleSendReqViaEmail,
  handleSendReqViaWhatsapp,
  handleSitterCalender,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  handleChangeTickMark,
  searchData,
  handleSearch
}) => {
  const [rowData, setRowData] = useState([])

  const viewContentFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: 'center', display: 'flex', gap: '5px' }} key={row.user_id}>
        <i
          className="ri-eye-line text-info h5 cursor-pointer"
          onClick={() => handleViewSitterData(row.user_id)}
        />
        <i
          className="ri-calendar-2-fill text-primary h5 cursor-pointer"
          onClick={() => handleSitterCalender(row.user_id)}
        />
        <Input type="checkbox" checked={row.tick_mark} onChange={() => handleChangeTickMark(row.user_id, row.tick_mark)}/>
        <i
          className="ri-mail-send-line text-warning h5 cursor-pointer"
          onClick={() => handleSendReqViaEmail(row.user_id)}
        />
        <i
          className="ri-whatsapp-line text-success h5 cursor-pointer"
          onClick={() => handleSendReqViaWhatsapp(row.user_id)}
        />
      </div>
    )
  }



  useEffect(() => {
    tableHeading.push({
      text: 'View',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%', cursor: 'pointer' },
      formatter: viewContentFormatter,
    })
    return () => {
      tableHeading.pop()
    }
  }, [])


  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i) {
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
  }, [tableData])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              
              <Col xs={4} className='mb-4'>
                <Label htmlFor="searchSitter">Search</Label>
                <Input
                  style={{ height: '35px' }}
                  name="searchSitter"
                  value={searchData}
                  onChange={handleSearch}
                  type="text"
                  className="form-control"
                  id="searchSitter"
                />
              </Col>
            </Row>
            <Table
              rows={rowData}
              columns={tableHeading}
              onTableChange={handleTablePageChange}
              page={page}
              sizePerPage={sizePerPage}
              totalSize={totalSize}
              isLoading={isLoading}
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default BookingFilteredSitterList
