import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { useEffect, useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, MobileTimePicker } from '@mui/x-date-pickers'  
import dayjs from 'dayjs'

const EditOvernightData = ({ data, updatedData }) => {
  const style = { borderRight: '1px solid #ced4da' }
  const [overnightTimeDiff, setOvernightTimeDiff] = useState('')

  const [overnightData, setOvernightData] = useState({
    overnight_start_date_time: {
      date: '',
      time: ''
    },
    overnight_end_date_time: {
      date: '',
      time: ''
    },
    other_details: data.other_details
  })
  const { overnight_start_date_time, overnight_end_date_time, other_details } = overnightData

  const handleRenderDates = () => {
    if(!data?.overnight_start_date_time?.date){
      const startDate = data?.overnight_start_date_time && data?.overnight_start_date_time?.slice(0, 16).split('T')
      const endDate = data?.overnight_end_date_time && data?.overnight_end_date_time?.slice(0, 16).split('T')
      setOvernightData({ ...overnightData,
        overnight_start_date_time: { ...overnightData.overnight_start_date_time, date: startDate?.[0], time: startDate?.[1] },
        overnight_end_date_time: { ...overnightData.overnight_end_date_time, date: endDate?.[0], time: endDate?.[1] } })}
  }

  useEffect(() => {
    data && data?.overnight_start_date_time && handleRenderDates()
  },[data])

  useEffect(() => {
    if(overnight_start_date_time.date !== '' && overnight_end_date_time.date !== ''){
      const dateA = new Date(`${overnight_start_date_time.date}T${overnight_start_date_time.time}`)
      const dateB = new Date(`${overnight_end_date_time.date}T${overnight_end_date_time.time}`)
      const timeDifferenceInMilliseconds = dateB - dateA
      let timeDifferenceInHours = timeDifferenceInMilliseconds / (1000 * 60 * 60) // In hours
      let timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60) // In Minutes
      timeDifferenceInHours = timeDifferenceInHours >=24 ? timeDifferenceInHours : 24
      const timeDifferenceInDays = timeDifferenceInHours >= 24 ? (timeDifferenceInHours / 24) | 0 : 1  // In Days
      const timeDifferenceInModulas = timeDifferenceInHours >= 24 ? timeDifferenceInHours % 24 : 0 // In Modulas
      const minutesDifferenceInModulas = timeDifferenceInMinutes % 60 // In Modulas minutes
      const dayDiff = timeDifferenceInModulas && ( (1 < timeDifferenceInModulas && timeDifferenceInModulas <= 5) 
      && minutesDifferenceInModulas >= 0) ? `${timeDifferenceInDays + 0.5} Days` : (timeDifferenceInModulas > 5 &&
        minutesDifferenceInModulas >= 0) ? `${timeDifferenceInDays + 1} Days` : timeDifferenceInDays
      const hoursDiff = timeDifferenceInHours < 24 ? `${timeDifferenceInHours} Hours` : ''
      const time = timeDifferenceInHours < 24 ? hoursDiff : dayDiff
      setOvernightTimeDiff(time)
      updatedData(overnightData)
    }
  }, [overnightData])
  
  return (
    <>
      <Row>
        <Col xs={6}>
          <FormGroup>
            <Label>Start Date</Label>
            <Input type='date' placeholder='start Date' min={new Date().toISOString().split('T')[0]}
              value={overnightData.overnight_start_date_time.date}
              onChange={(e) => 
                setOvernightData({ ...overnightData,
                  overnight_start_date_time: { ...overnightData.overnight_start_date_time, date: e.target.value }
                })}>  
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Start Time</Label>
            <br/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                sx={{ marginTop: '5px' }}
                ampm={false}
                defaultValue={dayjs()}
                value={dayjs(`2014-08-18T${overnightData.overnight_start_date_time.time}`)}
                onChange={(e) => setOvernightData({ ...overnightData, overnight_start_date_time:
                  { ...overnightData.overnight_start_date_time, time: e.format('HH:mm') } })}
              />
            </LocalizationProvider>
          </FormGroup>
        </Col>
        <Col xs={6}>
          <FormGroup>
            <Label>End Date</Label>
            <Input type='date' placeholder='start Date' min={new Date().toISOString().split('T')[0]}
              value={overnightData.overnight_end_date_time.date}
              onChange={(e) => setOvernightData({ ...overnightData, overnight_end_date_time:
               { ...overnightData.overnight_end_date_time, date: e.target.value } })}>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>End Time</Label>
            <br/>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileTimePicker
                sx={{ marginTop: '5px' }}
                ampm={false}
                defaultValue={dayjs()}
                value={dayjs(`2014-08-18T${overnightData.overnight_end_date_time.time}`)}
                onChange={(e) => setOvernightData({ ...overnightData, overnight_end_date_time: 
                  { ...overnightData.overnight_end_date_time, time: e.format('HH:mm') } })}
              />
            </LocalizationProvider>
          </FormGroup>
        </Col>
        <p className='font-bold text-end'>Total days: {overnightTimeDiff}</p>
        <Row>
          <Col lg='6' style={style}>
            <FormGroup>
              <Label>Other Details</Label>
              <Input value={other_details} type="textarea" className="form-control"
                onChange={(e) => setOvernightData({ ...overnightData, other_details: e.target.value })} />
            </FormGroup>
          </Col>
        </Row>
      </Row>
    </>
  )
}

export default EditOvernightData