import { useEffect, useState } from 'react'
import { updateBookingRequestListTableHeading } from '../../Constants'
import { getBookingDetailsDataById, getUpdateBookingDetailsListReq, updateBookingById } from '../../Api'
import ViewBookingDetailsData from 'Components/ViewBookingDetailsData'
import EditBookingDetailsData from 'Components/EditBookingDetailsData'
import UpdateBookingDetailsList from 'Components/ParentUpdateBooking'
import UpdateBookingDetailsModal from 'Components/updateBookingDetailsData'
import SitterStatus from 'Components/statusNote'

const UpdateBookingDetails = () => {
  const [parentUserId, setParentUserId] = useState('')
  const [bookingId, setBookingId] = useState('')
  const [bookingList, setBookingList] = useState([])
  const [bookingData, setBookingData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 10,
  })
  const { currentPage, totalSize } = paginationValue
  const [isBookingViewModal, setIsBookingViewModal] = useState(false)
  const [isBookingEditModal, setIsBookingEditModal] = useState(false)
  const [isBookingUpdateModal, setIsBookingUpdateModal] = useState(false)
  const [isBookingSearchModal, setIsBookingSearchModal] = useState(false)
  const [isCsvLoaded, setIsCsvLoaded] = useState(false)
  const [filteredData, setFilteredData] = useState({
    count: 0,
    pageSize: ''
  })
  const { count, pageSize } = filteredData
  const [searchData, setSearchData] = useState('')
  const [upadtedStatus, setUpadtedStatus] = useState('')
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false)

  const handleBookingDetailList = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUpdateBookingDetailsListReq(queryParams)
      setBookingList(data.data.results)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.data.count,
      })
      setFilteredData({ ...filteredData, count: data.data.count })
      setIsLoading(false)
      return data
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(() => {
    handleBookingDetailList({ page: 1, search: '' })
  }, [])

  const handleTablePageChangeTags = (type, { page, sizePerPage, sortField, sortOrder }) => {
    handleBookingDetailList({
      page: page,
      search: searchData,
    })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }

  const getDataByUserId = async(UserId) => {
    try {
      const { data } = await getBookingDetailsDataById(UserId)
      setBookingData(data.data)
      setIsLoading(false)
      return data.data
    } catch (error) {
      console.log(error)
    }
  }

  const handleViewBookingData = (bookingId) => {
    getDataByUserId(bookingId)
    setIsBookingViewModal(true)
  }

  const handleEditBookingData = (bookingId, parent_user_id) => {
    setBookingId(bookingId)
    setParentUserId(parent_user_id)
    getDataByUserId(bookingId).then((res) => {
      res.raw_status == 'booking_confirmed' ? setIsBookingUpdateModal(true) : setIsBookingEditModal(true)
    })

  }

  const handleSitterBookingData = (bookingId, parent_user_id) => {
    setBookingId(bookingId)
    setParentUserId(parent_user_id)
    getDataByUserId(bookingId)
    setIsBookingSearchModal(true)
  }


  const handleDownloadCsvData = () => {
    handleBookingDetailList({
      page: 1,
      search: searchData,
    }).then((res) => {if(res.response_code == 80){
      // setCsvData(res.data.results)
      setIsCsvLoaded(true)
    }})
  }

  const filterCsvData = (csvData) => {
    const data = csvData.map((obj) => ({
      BookingID: obj.booking_id, RecievedOn: obj.created_on, ParentName: obj.parent_full_name, Email: obj.parent_email,
      Dogs: obj.dogs, ServiceType: obj.service_type, Dates: obj.dates,
      Location: obj.location_type }))
    return data
  }

  const handleFilter = () => {
    handleBookingDetailList({
      page: 1,
      start_month_sort: '',
    }).then(() => setIsCsvLoaded(false))
  }

  const handleSearch = (e) => {
    const val = e.target.value
    setSearchData(e.target.value)
    handleBookingDetailList({
      page: 1,
      search: val,
    })
    setIsCsvLoaded(false)
  }

  const handleUpdateStatusData = (data) => {
    console.log(data)
    setUpadtedStatus(data.notes)
    setBookingId(data.id)
    setIsNotesModalOpen(true)
  }

  const handleUpdateStatusReq = () => {
    updateBookingById({ 'admin_notes': upadtedStatus }, bookingId).then((res) => {
      res.data.response_code == 80 && setIsNotesModalOpen(false), setUpadtedStatus(''),
      handleBookingDetailList({ page: 1, search: '' })
    })
  }

  return (
    <>
      <UpdateBookingDetailsList
        handleTablePageChange={handleTablePageChangeTags}
        tableData={bookingList}
        handleViewBookingData={handleViewBookingData}
        handleEditBookingData={handleEditBookingData}
        handleSitterBookingData={handleSitterBookingData}
        tableHeading={updateBookingRequestListTableHeading}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        renderTable={() => handleBookingDetailList({ page: 1, search: '' })}
        dataCount={count}
        handleFilter={handleFilter}
        csvData={filterCsvData(bookingList)}
        isCsvLoaded={isCsvLoaded}
        handleCallDataForCsv={handleDownloadCsvData}
        handleSearch={handleSearch}
        handleUpdateStatus={handleUpdateStatusData}
      />

      {isNotesModalOpen && <SitterStatus
        isOpen={isNotesModalOpen}
        sitterStatus={upadtedStatus}
        handleChangeStatus={(e) => setUpadtedStatus(e.target.value)}
        handleUpdateStatusReq={handleUpdateStatusReq}
        handleClose={() => {setIsNotesModalOpen(false), setUpadtedStatus('')}}
      />}

      {isBookingViewModal && <ViewBookingDetailsData 
        isOpen={isBookingViewModal}
        handleClose={() => {setIsBookingViewModal(false); setBookingData([])}}
        bookingData={bookingData}
      />}

      
      {isBookingEditModal && <EditBookingDetailsData 
        isOpen={isBookingEditModal}
        handleClose={() => {setIsBookingEditModal(false); setBookingData([])}}
        bookingId={bookingId}
        parentUserId={parentUserId}
      />}

      {isBookingUpdateModal && <UpdateBookingDetailsModal 
        isOpen={isBookingUpdateModal}
        handleClose={() => {setIsBookingUpdateModal(false); setBookingData([])}}
        bookingId={bookingId}
        parentUserId={parentUserId}
      />}

    </>
  )
}

export default UpdateBookingDetails