export const SitterFilteredListTableHeading = [
  {
    text: 'S.No.',
    dataField: 'sNo',
  },
  {
    text: 'Name',
    dataField: 'first_name',
  },
  {
    text: 'Family Name',
    dataField: 'last_name',
  },
  {
    text: 'Email',
    dataField: 'email',
  },
  {
    text: 'Mobile',
    dataField: 'mobile',
  },
  {
    text: 'Age',
    dataField: 'age',
  },
  {
    text: 'Services',
    dataField: 'offered_services',
  }
]