import FilteredSitterList from 'Components/Filtertable'
import { useEffect, useState, useRef } from 'react'
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { SitterFilteredListTableHeading } from '../../Constants'
import Select from 'react-select'
import {
  createCalenderEventreq,
  createDaycareBookingCalenderEventreq,
  deleteCalenderEventreq,
  editCalenderEventreq,
  getCalenderEventByIdreq,
  getCalenderEventreq,
  getUserListReqByFilters,
  sendNotificationReq,
} from '../../Filter.Apis'
import './style.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ViewSitterProfile from 'Components/ViewSitterProfile'
import { clearUserActiveStatusDataByIdReq, getUserDataByIdReq, upadteUserActiveStatusDataByIdReq }
  from 'Views/Dashboard/Views/Users/Users.Apis'
import Calender from 'Components/FullCalender'
import DatePicker from 'react-multi-date-picker'
import SweetAlert from 'react-bootstrap-sweetalert'
import { DateRangePicker } from 'rsuite'
import 'rsuite/dist/rsuite.css'
import * as moment from 'moment'
import CsvDownloadButton from 'react-json-to-csv'
import { age_of_dog_option, elevator_options, floor_options, languages_option, maybe_option, outdoor_spaces_options,
  schedule_options, size_of_dog_option } from './selectOptions'
import { daycare_offered_services_options, overnight_offered_services_options } from 'Components/EditSitterprofile/helper'

toast.configure()

const AllFilters = () => {
  const [usersList, setUsersList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [sitter, setSitter] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isCreateCalenderModalOpen, setIsCreateCalenderModalOpen] = useState(false)
  const [daycareBookingModal, setDaycareBookingModal] = useState(false)
  const [id, setId] = useState('')
  const [eventsData, setEventsData] = useState([])
  const [multipleDates, setMultipleDates] = useState([])
  const [preferredLanguages, setPreferredLanguages] = useState([])
  const [sizeOfDogFilter, setsizeOfDogFilter] = useState([])
  const [ageOfDogFilter, setageOfDogFilter] = useState([])
  const [toiletTrainedFilter, settoiletTrainedFilter] = useState([])
  const [carefor2Filter, setCarefor2Filter] = useState([])
  const [scheduleFilter, setSchdeuleFilter] = useState([])
  const [floorFilter, setFloorFilter] = useState([])
  const [elevatorFilter, setElevatorFilter] = useState([])
  const [dogswithIssueFilter, setDogswithIssueFilter] = useState([])
  const [outdoorSpacesFilter, setOutdoorSpacesFilter] = useState([])
  const [startDateEvent, setStartDateEvent] = useState()
  const [endDateEvent, setEndDateEvent] = useState()
  const [eventId, setEventId] = useState('')
  const [showEventAlert, setShowEventAlert] = useState(false)
  const [showEventConfirm, setShowEventConfirm] = useState(false)
  const [sitterNameByEvent, setSitterNameByEvent] = useState('')
  const [userInfoModal, setUserInfoModal] = useState(false)
  const [isCsvLoaded, setIsCsvLoaded] = useState(false)
  const [csvData, setCsvData] = useState([])
  const [eventData, setEventData] = useState('')
  const [eventTitle, setEventTitle] = useState('')
  const [eventType, setEventType] = useState('')
  const [eventTypeErrorMsg, setEventTypeErrorMsg] = useState()
  const [eventTimeData, setEventTimeData] = useState({
    eventStartDateTime: '',
    eventEndDateTime: ''
  })
  const { eventStartDateTime, eventEndDateTime } = eventTimeData
  const [updateEventDisabled, setUpdateEventDisabled] = useState(true)
  const [startRangeDate, setStartRangeDate] = useState(0)
  const [endRangeDate, setEndRangeDate] = useState(0)
  const [calenderEventData, setCalenderEventData] = useState({
    title: '',
    description: '',
    event_type: '',
    start_time:'',
    end_time: '',
    startDateTime: '',
    endDateTime: '',
    multiple_Dates: []
  })

  const { title, description, event_type, start_time, end_time, startDateTime, endDateTime, multiple_Dates } = calenderEventData
  const [filterData, setFilterData] = useState({
    page: 1,
    start_date: '',
    end_date: '',
    Sensitivity: '',
    search_start_time: '00:00',
    search_end_time: '23:59',
    multiple_dates: [],
    offered_services: [],
    offered_services_array: [],
    have_your_own_dog: '',
    have_your_own_cat: '',
    preferred_languages: [],
    daily_schedule: '',
    floor: '',
    home_has_elevator: '',
    size_of_dog: '',
    age_of_dog: '',
    have_children: '',
    comfortable_with_non_toilet_trained: '',
    comfortable_with_two_dogs: '',
    comfortable_with_separation_anxiety: '',
    is_inactive: '',
    has_driving_license: '',
    has_car: ''
  })

  let {
    page,
    start_date,
    end_date,
    Sensitivity,
    search_start_time,
    search_end_time,
    multiple_dates,
    offered_services,
    offered_services_array,
    have_your_own_dog,
    have_your_own_cat,
    preferred_languages,
    daily_schedule,
    floor,
    home_has_elevator,
    size_of_dog,
    age_of_dog,
    have_children,
    comfortable_with_non_toilet_trained,
    comfortable_with_two_dogs,
    comfortable_with_separation_anxiety,
    is_inactive,
    has_driving_license,
    has_car
  } = filterData

  const [notificationUserName, setNotificationUserName] = useState('')
  const [inActiveData, setInActiveData] = useState({
    statusEndDate: '',
    userName: ''
  })
  const { statusEndDate, userName } = inActiveData

  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 10,
  })

  const { currentPage, totalSize } = paginationValue

  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const [notifictionUserId, setNotifictionUserId] = useState('')
  const [notificationFormData, setNotificationFormData] = useState({
    wix_id: '', service_type: ''
  })
  const { wix_id,  service_type } = notificationFormData

  const handleInputChange = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    })
  }

  const handleCalenderChange = (e) => {
    setCalenderEventData({
      ...calenderEventData,
      [e.target.name]: e.target.value,
    })
  }

  const formatMultiSelectDate = (data) => {
    const formatedData = data.map((value) => value.year + '-' + value.month + '-' + value.day)
    return formatedData
  }
  const handleMultipleDate = (e) => {
    setMultipleDates(e)
  }
  const formatMultiSelectData = (data) => {
    const formatedData = data.map(({ value }) => value)
    return formatedData
  }

  const handleLanguage = (e) => {
    setPreferredLanguages(e)
  }

  const handlesizeOfDog = (e) => {
    setsizeOfDogFilter(e)
  }

  const handleageOfDog = (e) => {
    setageOfDogFilter(e)
  }

  const handleToiledTrained = (e) => {
    settoiletTrainedFilter(e)
  }

  const handleCarefor2Filter = (e) => {
    setCarefor2Filter(e)
  }

  const handleOfferedServices = (e) => {
    const offeredServices = []
    e.map((item) => {
      offeredServices.push(item.value)
      setFilterData({
        ...filterData,
        offered_services: e,
        offered_services_array: offeredServices
      })
    })
  }

  const handleDogswithIssueFilter = (e) => {
    setDogswithIssueFilter(e)
  }

  const handleOutdoorSpacesFilter = (e) => {
    setOutdoorSpacesFilter(e)
  }

  const handleschdeuleFilter = (e) => {
    setSchdeuleFilter(e)
  }

  const handleFloorFilter = (e) => {
    setFloorFilter(e)
  }

  const handleElevatorFilter = (e) => {
    setElevatorFilter(e)
  }

  const submitFilter = () => {
    handleSitterUsersKycData({
      page,
      start_date,
      end_date,
      Sensitivity,
      search_start_time,
      search_end_time,
      multiple_dates: formatMultiSelectDate(multipleDates),
      offered_services: offered_services_array,
      have_your_own_dog,
      have_your_own_cat,
      preferred_languages: formatMultiSelectData(preferredLanguages),
      daily_schedule: formatMultiSelectData(scheduleFilter),
      floor: formatMultiSelectData(floorFilter),
      home_has_elevator: formatMultiSelectData(elevatorFilter),
      size_of_dog: formatMultiSelectData(sizeOfDogFilter),
      age_of_dog: formatMultiSelectData(ageOfDogFilter),
      have_children,
      comfortable_with_non_toilet_trained: formatMultiSelectData(toiletTrainedFilter),
      comfortable_with_two_dogs: formatMultiSelectData(carefor2Filter),
      comfortable_with_separation_anxiety: formatMultiSelectData(dogswithIssueFilter),
      outdoor_spaces: formatMultiSelectData(outdoorSpacesFilter),
      page_size: '',
      is_inactive,
      has_car,
      has_driving_license
    }).then(() => {
      setIsCsvLoaded(false)
    })
  }

  const handleSitterUsersKycData = async(queryParams) => {
    try {
      setIsLoading(true)
      const { data } = await getUserListReqByFilters(queryParams)
      setUsersList(data.data.results)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize : data.data.count
      })
      data.data.results.length === 0 ? alert('No Sitter Found') : null
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  const getDataByUserId = async(UserId) => {
    try {
      const { data } = await getUserDataByIdReq(UserId)
      setSitter(data.data)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const toggleCreateModel = () => {
    setIsCreateModalOpen(true)
  }

  const handleCloseModal = () => {
    setSitter([])
    setIsCreateModalOpen(false)
  }

  const handleViewSitterData = (UserId) => {
    getDataByUserId(UserId)
    toggleCreateModel()
  }

  const toggleCreateCalenderModel = () => {
    setIsCreateCalenderModalOpen(true)
  }

  const handleCloseCalenderModal = () => {
    setIsCreateCalenderModalOpen(false)
  }

  const getCalenderDataByUserId = async(UserId) => {
    try {
      const { data } = await getCalenderEventreq(UserId)
      setEventsData(data.data)
      setSitterNameByEvent(data.sitter_name)
      setInActiveData({
        ...inActiveData,
        userName: data.in_active_status_text
      })
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const ViewCalender = (UserId) => {
    getCalenderDataByUserId(UserId)
    setId(UserId)
    toggleCreateCalenderModel()
  }

  const sendNotification = (UserId, userName) => {
    setNotifictionUserId(UserId)
    setNotificationUserName(userName)
    setShowNotificationModal(true)
  }

  const handleSendNoticationById = async() => {
    const response = await sendNotificationReq(
      {
        wix_id,
        user_id: notifictionUserId,
        service_type
      }
    )
    if (response.data.response_code === 90) {
      toast.error(response.data.developer_message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      })
    }
    else{
    
      setShowNotificationModal(false)
      setNotifictionUserId('')
      setNotificationFormData({ ...notificationFormData, wix_id: '', service_type: '' })
    }
  }

  const createFilterEvent = async() => {
    if(event_type == ''){
      setEventTypeErrorMsg('Please select the event type')
    }else{
      await createCalenderEventreq(
        {
          title,
          description: description,
          start_time: startDateTime,
          end_time: endDateTime,
          event_type,
        },
        id
      )
      setIsOpen(false)
      setCalenderEventData({
        title: '',
        description: '',
        event_type: ''

      })
      setEventTypeErrorMsg('')
      getCalenderDataByUserId(id)
    }
  }

  const select = async(start) => {
    setStartDateEvent(start.startStr)
    setEndDateEvent(start.endStr)
    setCalenderEventData({
      ...calenderEventData,
      startDateTime : moment(new Date(start.startStr)).format('YYYY-MM-DDT00:00'),
      endDateTime : moment(new Date(start.endStr)).subtract(1, 'days').format('YYYY-MM-DDT23:59')
    })
    setIsOpen(true)
  }

  const enableEventAlert = async(e) => {
    setEventId(e.event._def.extendedProps.event_id)
    try {
      const { data } = await getCalenderEventByIdreq(e.event._def.extendedProps.event_id)
      console.log(data.data)
      setEventData(data.data.description)
      setEventTitle(data.data.title)
      setEventType(data.data.event_type)
      setEventTimeData({
        eventStartDateTime: (data.data.start).slice(0, 16),
        eventEndDateTime: (data.data.end).slice(0, 16)
      })
      setUserInfoModal(true)
      // setShowEventAlert(true)

    } catch (error) {
      console.error(error)
    }
  }

  const removeEvent = async() => {
    console.log(eventId)
    await deleteCalenderEventreq(eventId)
    getCalenderDataByUserId(id)
    setShowEventAlert(false)
    setShowEventConfirm(true)
    setUserInfoModal(false)
  }

  const clearAllFilter = () => {
    setFilterData({
      ...filterData,
      start_date: '',
      end_date: '',
      Sensitivity: '',
      selected_service_type: '',
      search_start_time: '00:00',
      search_end_time: '23:59',
      multipleDates: multipleDates.splice(0,multipleDates.length),
      offered_services: [],
      offered_services_array: [],
      have_your_own_dog: '',
      have_your_own_cat: '',
      preferred_languages: [],
      daily_schedule: '',
      floor: '',
      home_has_elevator: '',
      size_of_dog: [],
      age_of_dog: [],
      have_children: '',
      comfortable_with_non_toilet_trained: [],
      comfortable_with_two_dogs: [],
      comfortable_with_separation_anxiety: [],
      is_inactive: '',
    })
    setPreferredLanguages([])
    setsizeOfDogFilter([])
    setageOfDogFilter([])
    settoiletTrainedFilter([])
    setCarefor2Filter([])
    setDogswithIssueFilter([])
    setOutdoorSpacesFilter([])
    setSchdeuleFilter([])
    setFloorFilter([])
    setElevatorFilter([])
    setIsCsvLoaded(false)
    setUsersList([])
    setPaginationValue({ ...paginationValue,
      currentPage: 1,
      totalSize: 10,
    })
  }

  const updateCalenderDataByUserId = async() => {
    try {
      const { data } = await editCalenderEventreq(eventId,{
        title: eventTitle,
        description: eventData,
        event_type: eventType,
        start_time: eventStartDateTime,
        end_time: eventEndDateTime,

      })
      setUserInfoModal(false)
      getCalenderDataByUserId(id)
      setUpdateEventDisabled(true)
    } catch (error) {
      console.log(error)
    }
  }

  const getDataByDate = async(data , startDate, endDate) => {
    if (data) {
      try {
        setFilterData({
          ...filterData,
          start_date: moment(new Date(startDate)).format('YYYY-MM-DD'),
          end_date : moment(new Date(endDate)).format('YYYY-MM-DD')
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleChange = (data) => {
    if (data && !data[0]) {
      if (startRangeDate != 0 && endRangeDate ==0) {
        endRangeDate == 0 && data && setEndRangeDate(data)
      }else if (startRangeDate == 0 && endRangeDate ==0) {
        startRangeDate == 0 && data && setStartRangeDate(data)
      }
    } else {
      if (data && data[0] && data[1]) {
        data && data[0] && setStartRangeDate(data[0])
        data && data[1] && setEndRangeDate(data[1])
        getDataByDate(data,data[0],data[1])
      }
    }
  }

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    setFilterData({
      ...filterData
      // page: 1
    })
    handleSitterUsersKycData({ 
      page,
      start_date,
      end_date,
      Sensitivity,
      search_start_time,
      search_end_time,
      multiple_dates: formatMultiSelectDate(multipleDates),
      offered_services: offered_services_array,
      have_your_own_dog,
      have_your_own_cat,
      preferred_languages: formatMultiSelectData(preferredLanguages),
      daily_schedule: formatMultiSelectData(scheduleFilter),
      floor: formatMultiSelectData(floorFilter),
      home_has_elevator: formatMultiSelectData(elevatorFilter),
      size_of_dog: formatMultiSelectData(sizeOfDogFilter),
      age_of_dog: formatMultiSelectData(ageOfDogFilter),
      have_children,
      comfortable_with_non_toilet_trained: formatMultiSelectData(toiletTrainedFilter),
      comfortable_with_two_dogs: formatMultiSelectData(carefor2Filter),
      comfortable_with_separation_anxiety: formatMultiSelectData(dogswithIssueFilter),
      outdoor_spaces: formatMultiSelectData(outdoorSpacesFilter),
      page_size: '',
      is_inactive,
      has_driving_license,
      has_car
    })
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    })
  }
  const getInActiveuserStatus = (e) => {
    setInActiveData({
      ...inActiveData,
      statusEndDate: e.target.value
    })
  }

  const submitInActiveStatus = async() => {
    const { data } = await upadteUserActiveStatusDataByIdReq({ 
      title: 'INACTIVE',
      description: 'INACTIVE',
      end_time: statusEndDate
    },
    id
    )
    getCalenderDataByUserId(id)
  }

  const clearInActiveStatus = async() => {
    const { data } = await clearUserActiveStatusDataByIdReq(id)
    getCalenderDataByUserId(id)
  }

  const createDaycareBookingEvent = async() => {
    if(event_type == ''){
      setEventTypeErrorMsg('Please select the event type')
    }else{
      const { data } = await createDaycareBookingCalenderEventreq(
        {
          title,
          description: description,
          multiple_dates: formatMultiSelectDate(multiple_Dates),
          event_type,
        },
        id
      )
      setDaycareBookingModal(false)
      setCalenderEventData({
        title: '',
        description: '',
        event_type: '',
        multiple_Dates: []
      })
      setEventTypeErrorMsg('')
      getCalenderDataByUserId(id)
    }
  }
  const ref = useRef()

  const handleCallDataForCsv = () => {
    try {
      setIsLoading(true)
      getUserListReqByFilters({ 
        page,
        start_date,
        end_date,
        Sensitivity,
        search_start_time,
        search_end_time,
        multiple_dates: formatMultiSelectDate(multipleDates),
        offered_services: offered_services_array,
        have_your_own_dog,
        have_your_own_cat,
        preferred_languages: formatMultiSelectData(preferredLanguages),
        daily_schedule: formatMultiSelectData(scheduleFilter),
        floor: formatMultiSelectData(floorFilter),
        home_has_elevator: formatMultiSelectData(elevatorFilter),
        size_of_dog: formatMultiSelectData(sizeOfDogFilter),
        age_of_dog: formatMultiSelectData(ageOfDogFilter),
        have_children,
        comfortable_with_non_toilet_trained: formatMultiSelectData(toiletTrainedFilter),
        comfortable_with_two_dogs: formatMultiSelectData(carefor2Filter),
        comfortable_with_separation_anxiety: formatMultiSelectData(dogswithIssueFilter),
        page_size: 200,
        outdoor_spaces: formatMultiSelectData(outdoorSpacesFilter),
        is_inactive,
        has_driving_license,
        has_car
      }).then((res) => {if(res.data.response_code == 80){
        setCsvData(res.data.data.results)
        setIsCsvLoaded(true)
      }})
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  } 

  const filterCsvData = (csvData) => {
    const data = csvData.map((obj) => ({
      FirstName: obj.first_name, LastName: obj.last_name, FullName: `${obj.first_name} ${obj.last_name}`, 
      Email: obj.email, FullAddress: obj.full_address, Mobile: obj.mobile }))
    return data
  }

  console.log(offered_services, 'offered_services')

  return (
    <>
      <div>
        <Row>
          <Row className='justify-content-between'>
            <h3 className='w-50'>Filters Type</h3>
            <div className='w-50 d-flex justify-content-end gap-2'>
              {isCsvLoaded ? <CsvDownloadButton data={filterCsvData(csvData)} /> :
                <Button onClick={handleCallDataForCsv}><i className='ri-download-2-line' ></i></Button>}
              <Button
                className="btn btn-danger"
                style={{ width: 'auto' }}
                type="button"
                onClick={clearAllFilter}
              >
                Clear All
              </Button>
            </div>
          </Row>
          <Col xs={1}>
            <FormGroup>
              <Label htmlFor="selected_service_type">Service</Label>
              <Input
                name="selected_service_type"
                value={filterData?.selected_service_type}
                onChange={handleInputChange}
                type="select"
                className="form-control"
                id="selected_service_type"
              >
                <option hidden>Select</option>
                <option value="overnight">Overnight</option>
                <option value="daycare">Daycare</option>
              </Input>

            </FormGroup>
          </Col>
          
          {filterData?.selected_service_type && <Col xs={3}>
            <FormGroup>
              <Label htmlFor="offered_services">Select Type</Label>
              <Select
                options={filterData?.selected_service_type == 'overnight' ?
                  overnight_offered_services_options : daycare_offered_services_options }
                isMulti
                onChange={handleOfferedServices}
                value={offered_services}
                name="offered_services"
                placeholder="Select Service"
              />
            </FormGroup>
          </Col>}
          {(offered_services_array.includes('overnight_parent_home')|| 
            offered_services_array.includes('overnight_sitter_home')) && (
            <>
              
              <Col xs={3}>
                <FormGroup>
                  <Label htmlFor="start_date">Start Date / End Date</Label><br/>
                  <DateRangePicker
                    onOk={() => getDataByDate(false)}
                    onSelect={(data) => handleChange(data)}
                    onChange={(data) => handleChange(data)}
                    onClean={() => {
                      setFilterData({
                        ...filterData,
                        start_date: '',
                        end_date: '',
                      })
                    }} 
                  />
                </FormGroup>
              </Col>
              <Col xs={3}>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="search_start_time">Start Time</Label><br/>
                      <Input
                        name="search_start_time"
                        value={search_start_time}
                        onChange={(e) => setFilterData({
                          ...filterData,
                          search_start_time: e.target.value
                        })}
                        type="time"
                        className="form-control"
                        id="search_start_time"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="search_end_time">End Time</Label><br/>
                      <Input
                        name="search_end_time"
                        value={search_end_time}
                        onChange={(e) => setFilterData({
                          ...filterData,
                          search_end_time: e.target.value
                        })}
                        type="time"
                        className="form-control"
                        id="search_end_time"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
              <Col xs={1}>
                <FormGroup>
                  <Label htmlFor="Sensitivity">Sensitivity</Label>
                  <Input
                    name="Sensitivity"
                    value={Sensitivity}
                    onChange={(e) => setFilterData({
                      ...filterData,
                      Sensitivity: e.target.value
                    })}
                    type="select"
                    errorMessage="Sensitivity is required"
                    className="form-control"
                    id="Sensitivity"
                  >
                    <option hidden value="">Select</option>
                    <option value="4">4 Hours</option>
                    <option value="8">8 Hours</option>
                    <option value="12">12 Hours</option>
                    <option value="24">1 Day</option>
                    <option value="48">2 Days</option>
                    <option value="72">3 Days</option>
                    <option value="96">4 Days</option>
                  </Input>
                </FormGroup>
              </Col>
            </>
          )}

          {(offered_services_array.includes('daycare_sitter_home') ||
          offered_services_array.includes('daycare_parent_home')) && (
            <>
              <Col xs={3}>
                <FormGroup>
                  <Label htmlFor="multiple_dates">Select Dates</Label>
                  <DatePicker 
                    className='d-block'
                    multiple
                    onChange={handleMultipleDate}
                    value={multipleDates}
                    dateFormat="YYYY-MM-DD"
                  />
                </FormGroup>
              </Col>
              <Col xs={4}>
                <Row>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="search_start_time">S.Time</Label><br/>
                      <Input
                        name="search_start_time"
                        value={search_start_time}
                        onChange={(e) => setFilterData({
                          ...filterData,
                          search_start_time: e.target.value
                        })}
                        type="time"
                        className="form-control"
                        id="search_start_time"
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6}>
                    <FormGroup>
                      <Label htmlFor="search_end_time">E.Time</Label><br/>
                      <Input
                        name="search_end_time"
                        value={search_end_time}
                        onChange={(e) => setFilterData({
                          ...filterData,
                          search_end_time: e.target.value
                        })}
                        type="time"
                        className="form-control"
                        id="search_end_time"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </>
          )}
          {filterData?.selected_service_type && <Col
            xs={1}
            className="d-flex align-items-center justify-content-center"
          >
            <Button
              className="btn btn-info w-75"
              type="button"
              onClick={submitFilter}
            >
              &#x1F50D;
            </Button>
          </Col>}
        </Row>
        <Row className="mb-4">
          <Accordion
            className="accordion border-0"
            preExpanded={[1, 2]}
            allowMultipleExpanded
          >
            <AccordionItem className="accordion-item border-0" uuid={0}>
              <AccordionItemHeading className=" py-0 backgroud_light">
                <AccordionItemButton
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    background: 'white',
                  }}
                >
                  <h4 className="accordion-header">Advance Filters</h4>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Col xs={12}>
                  <Form>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <Row className='justify-content-between'>
                            <Label htmlFor="have_your_own_dog" className='w-50'>Has Dog</Label>
                            <p className='text-primary w-50 m-0 text-end' 
                              style={{ textTransform: 'underline', cursor: 'pointer' }} 
                              onClick={() => setFilterData({
                                ...filterData, have_your_own_dog : ''
                              })}>Clear</p>
                          </Row>
                          <Input
                            name="have_your_own_dog"
                            placeholder="Enter "
                            value={have_your_own_dog}
                            onChange={handleInputChange}
                            type="select"
                            className="form-control"
                            id="have_your_own_dog"
                          >
                            <option hidden value="">Select</option>
                            <option value="True">Yes</option>
                            <option value="False">No</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="size_of_dog">Size Of Dog</Label>
                          <Select
                            options={size_of_dog_option}
                            isMulti
                            onChange={handlesizeOfDog}
                            value={sizeOfDogFilter}
                            name="size_of_dog"
                            placeholder="Select Sizes"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="age_of_dog">Age of Dog</Label>
                          <Select
                            options={age_of_dog_option}
                            isMulti
                            onChange={handleageOfDog}
                            value={ageOfDogFilter}
                            name="age_of_dog"
                            placeholder="Select Ages"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Row className='justify-content-between'>
                            <Label htmlFor="have_your_own_cat" className='w-50'>Has Cat</Label>
                            <p className='text-primary w-50 m-0 text-end'
                              style={{ textTransform: 'underline', cursor: 'pointer' }} 
                              onClick={() => setFilterData({
                                ...filterData, have_your_own_cat : ''
                              })}>Clear</p>
                          </Row>
                          <Input
                            name="have_your_own_cat"
                            value={have_your_own_cat}
                            onChange={handleInputChange}
                            type="select"
                            className="form-control"
                            id="have_your_own_cat"
                          >
                            <option hidden value=''>Select</option>
                            <option value="True">Yes</option>
                            <option value="False">No</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="preferred_languages">Languages</Label>
                          <Select
                            options={languages_option}
                            isMulti
                            onChange={handleLanguage}
                            value={preferredLanguages}
                            name="preferred_languages"
                            placeholder="Select Languages"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="daily_schedule">Schedule</Label>
                          <Select
                            options={schedule_options}
                            isMulti
                            onChange={handleschdeuleFilter}
                            value={scheduleFilter}
                            name="daily_schedule"
                            placeholder="Select"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="floor">Floor</Label>
                          <Select
                            options={floor_options}
                            isMulti
                            onChange={handleFloorFilter}
                            value={floorFilter}
                            name="floor"
                            placeholder="Select Floor"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="home_has_elevator">Elevator</Label>
                          <Select
                            options={elevator_options}
                            isMulti
                            onChange={handleElevatorFilter}
                            value={elevatorFilter}
                            name="home_has_elevator"
                            placeholder="Select"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <Row className='justify-content-between'>
                            <Label htmlFor="have_children" className='w-75'>Have Children</Label>
                            <p className='text-primary w-25 m-0 text-end'
                              style={{ textTransform: 'underline', cursor: 'pointer' }} 
                              onClick={() => setFilterData({
                                ...filterData, have_children : ''
                              })}>Clear</p>
                          </Row>
                          <Input
                            name="have_children"
                            value={have_children}
                            onChange={handleInputChange}
                            type="select"
                            className="form-control"
                            id="have_children"
                          >
                            <option hidden value=''>Select</option>
                            <option value="True">Yes</option>
                            <option value="False">No</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="comfortable_with_non_toilet_trained">Toilet training</Label>
                          <Select
                            options={maybe_option}
                            isMulti
                            onChange={handleToiledTrained}
                            value={toiletTrainedFilter}
                            name="comfortable_with_non_toilet_trained"
                            placeholder="Select"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="comfortable_with_two_dogs">Care for 2 dogs</Label>
                          <Select
                            options={maybe_option}
                            isMulti
                            onChange={handleCarefor2Filter}
                            value={carefor2Filter}
                            name="comfortable_with_two_dogs"
                            placeholder="Select"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="comfortable_with_separation_anxiety">Dogs with issues</Label>
                          <Select
                            options={maybe_option}
                            isMulti
                            onChange={handleDogswithIssueFilter}
                            value={dogswithIssueFilter}
                            name="comfortable_with_separation_anxiety"
                            placeholder="Select"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}>
                        <FormGroup>
                          <Label htmlFor="outdoor_spaces">outdoor spaces</Label>
                          <Select
                            options={outdoor_spaces_options}
                            isMulti
                            onChange={handleOutdoorSpacesFilter}
                            value={outdoorSpacesFilter}
                            name="outdoor_spaces"
                            placeholder="Select"
                          />
                        </FormGroup>
                      </Col>
                    
                      <Col xs={3}>
                        <FormGroup>
                          <Row className='justify-content-between'>
                            <Label htmlFor="is_inactive" className='w-75'>Sitter is inactive Today</Label>
                            <p className='text-primary w-25 m-0 text-end'
                              style={{ textTransform: 'underline', cursor: 'pointer' }} 
                              onClick={() => setFilterData({
                                ...filterData, is_inactive : ''
                              })}>Clear</p>
                          </Row>
                          <Input
                            name="is_inactive"
                            value={is_inactive}
                            onChange={handleInputChange}
                            type="select"
                            className="form-control"
                            id="is_inactive"
                          >
                            <option hidden value=''>Select</option>
                            <option value="1">YES</option>
                            <option value="0">NO</option>
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col xs={3}>
                        <FormGroup>
                          <Row className='justify-content-between'>
                            <Label htmlFor="has_driving_license" className='w-75'>Has Driving License</Label>
                            <p className='text-primary w-25 m-0 text-end'
                              style={{ textTransform: 'underline', cursor: 'pointer' }} 
                              onClick={() => setFilterData({
                                ...filterData, has_driving_license : ''
                              })}>Clear</p>
                          </Row>
                          <Input
                            name="has_driving_license"
                            value={has_driving_license}
                            onChange={handleInputChange}
                            type="select"
                            className="form-control"
                            id="has_driving_license"
                          >
                            <option hidden value=''>Select</option>
                            <option value="True">Yes</option>
                            <option value="False">No</option>
                          </Input>
                        </FormGroup>
                      </Col>

                      <Col xs={3}>
                        <FormGroup>
                          <Row className='justify-content-between'>
                            <Label htmlFor="has_car" className='w-75'>Has Car</Label>
                            <p className='text-primary w-25 m-0 text-end'
                              style={{ textTransform: 'underline', cursor: 'pointer' }} 
                              onClick={() => setFilterData({
                                ...filterData, has_car : ''
                              })}>Clear</p>
                          </Row>
                          <Input
                            name="has_car"
                            value={has_car}
                            onChange={handleInputChange}
                            type="select"
                            className="form-control"
                            id="has_car"
                          >
                            <option hidden value=''>Select</option>
                            <option value="True">Yes</option>
                            <option value="False">No</option>
                          </Input>
                        </FormGroup>
                      </Col>

                    </Row>
                    <Row className="justify-content-center">
                      <Col
                        xs={5}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <Button
                          className="btn btn-success w-75"
                          type="button"
                          onClick={submitFilter}
                        >
                          Apply Filter
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </Row>
      </div>
      <FilteredSitterList
        handleTablePageChange={handleTablePageChange}
        tableData={usersList}
        tableHeading={SitterFilteredListTableHeading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
        handleViewSitterData={handleViewSitterData}
        ViewCalender={ViewCalender}
        sendNotification={sendNotification}
      />

      <ViewSitterProfile
        isOpen={isCreateModalOpen}
        toggleModal={toggleCreateModel}
        sitterData={sitter}
        handleClose={handleCloseModal}
      />

      <Calender
        isOpen={isCreateCalenderModalOpen}
        toggleModal={toggleCreateCalenderModel}
        handleClose={handleCloseCalenderModal}
        eventsData={eventsData}
        selectable={true}
        select={select}
        timeZone='UTC'
        eventClick={enableEventAlert}
        sitterName={sitterNameByEvent}
        inActiveEndDate={getInActiveuserStatus}
        statusEndDate={statusEndDate}
        submitInActiveStatus={submitInActiveStatus}
        clearInActiveStatus={clearInActiveStatus}
        userActiveStatusText={userName}
        handleCreateDaycareModal={() => {setDaycareBookingModal(true)}}
      />
      {showEventAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={removeEvent}
          onCancel={() => setShowEventAlert(false)}
        ></SweetAlert>
      ) : null}

      {showEventConfirm ? (
        <SweetAlert
          success
          title="Deleted!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowEventAlert(false)
            setShowEventConfirm(false)
            setUserInfoModal(false)
          }}
        >
          Event has been Deleted
        </SweetAlert>
      ) : null}

      {daycareBookingModal && (
        <div>
          <Modal size="lg" isOpen={daycareBookingModal} backdrop="static">
            <ModalHeader>Block Sitter`s Calendar</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label>Select Dates</Label>
                    <DatePicker
                      ref={ref}
                      className='d-block'
                      multiple
                      onChange={(e) => {setCalenderEventData({
                        ...calenderEventData,
                        multiple_Dates : e
                      })}}
                      value={multiple_Dates}
                      dateFormat="YYYY-MM-DD"
                    >
                      <Button className='py-1' style={{ position: 'relative', bottom: '5px' }}
                        color='primary' onClick={() => ref.current.closeCalendar()}>
                      close
                      </Button>
                    </DatePicker>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <Input
                  name="title"
                  value={title}
                  onChange={handleCalenderChange}
                  type="text"
                  className="form-control"
                  id="title"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <Input
                  name="description"
                  value={description}
                  onChange={handleCalenderChange}
                  type="textarea"
                  className="form-control"
                  id="description"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="event_type">Event Type</Label>
                <Input
                  name="event_type"
                  value={event_type}
                  onChange={handleCalenderChange}
                  type="select"
                  errorMessage="event_type is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="event_type"
                >
                  <option hidden>Select</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="temporary_blocked">Temporary Block</option>
                  <option value="booking_confirmed">Confirmed Booking</option>
                </Input>
                <p className='text-danger font-weight-bold'>{eventTypeErrorMsg}</p>
              </FormGroup>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={createDaycareBookingEvent}
                    >
                      Create
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setDaycareBookingModal(false)
                        setCalenderEventData({
                          ...calenderEventData,
                          title: '',
                          description: '',
                          event_type: '',
                          multiple_Dates: []
                        })
                        setEventTypeErrorMsg('')
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}

      {userInfoModal && (
        <div>
          <Modal size="lg" isOpen={userInfoModal} backdrop="static">
            <ModalHeader>Sitter`s Calendar Events Data</ModalHeader>
            <ModalBody>
              <Row className='justify-content-end pe-3'>
                {updateEventDisabled && eventType === 'in_active' ? null : (
                  <Button onClick={() => {setUpdateEventDisabled(false)}} style={{ width:'auto' }} color='primary'>Edit</Button>
                )}
                <Button 
                  onClick={() => {setShowEventAlert(true)}} 
                  style={{ width:'auto', marginLeft: '10px' }} 
                  color='danger'>Delete</Button>
              </Row>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="eventStartDateTime">Start Date Time</Label>
                    <Input
                      name="eventStartDateTime"
                      value={eventStartDateTime}
                      onChange={(e) => {
                        setEventTimeData({
                          ...eventTimeData,
                          eventStartDateTime: e.target.value
                          
                        })
                      }}
                      type="datetime-local"
                      className="form-control"
                      id="eventStartDateTime"
                      disabled={updateEventDisabled}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="eventEndDateTime">End Date Time</Label>
                    <Input
                      name="eventEndDateTime"
                      value={eventEndDateTime}
                      onChange={(e) => {
                        setEventTimeData({
                          ...eventTimeData,
                          eventEndDateTime: e.target.value
                        })
                      }}
                      type="datetime-local"
                      className="form-control"
                      id="eventEndDateTime"
                      min={startDateTime}
                      disabled={updateEventDisabled}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor="updatedTitle">Title</Label>
                <Input
                  name="updatedTitle"
                  value={eventTitle}
                  onChange={(e) => setEventTitle(e.target.value)}
                  type="text"
                  className="form-control"
                  id="updatedTitle"
                  disabled={updateEventDisabled}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="updatedDescription">Description</Label>
                <Input
                  name="updatedDescription"
                  value={eventData}
                  onChange={(e) => setEventData(e.target.value)}
                  type="textarea"
                  className="form-control"
                  id="updatedDescription"
                  disabled={updateEventDisabled}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="event_type">Event Type</Label>
                <Input
                  name="event_type"
                  value={eventType}
                  onChange={(e) => setEventType(e.target.value)}
                  type="select"
                  errorMessage="event_type is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="event_type"
                  disabled={updateEventDisabled}
                >
                  <option hidden>Select</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="temporary_blocked">Temporary Block</option>
                  <option value="booking_confirmed">Confirmed Booking</option>
                </Input>
              </FormGroup>
              <ModalFooter>
                <div className="row w-100">
                  <div className="col-6">
                    {!updateEventDisabled && (
                      <Button
                        className="btn btn-info w-75"
                        type="button"
                        onClick={updateCalenderDataByUserId}
                      >
                         Update
                      </Button>
                    )}
                    
                  </div>
                  <div className="col-6">
                    
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setUserInfoModal(false)
                        setUpdateEventDisabled(true)
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}

      {isOpen && (
        <div>
          <Modal size="lg" isOpen={isOpen} backdrop="static">
            <ModalHeader>Block Sitter`s Calendar</ModalHeader>
            <ModalBody>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="startDateTime">Start Date Time</Label>
                    <Input
                      name="startDateTime"
                      value={startDateTime}
                      onChange={handleCalenderChange}
                      type="datetime-local" 
                      className="form-control"
                      id="startDateTime"
                    />
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label htmlFor="endDateTime">End Date Time</Label>
                    <Input
                      name="endDateTime"
                      value={endDateTime}
                      onChange={handleCalenderChange}
                      type="datetime-local"
                      className="form-control"
                      id="endDateTime"
                      min={startDateTime}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label htmlFor="title">Title</Label>
                <Input
                  name="title"
                  value={title}
                  onChange={handleCalenderChange}
                  type="text"
                  className="form-control"
                  id="title"
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="description">Description</Label>
                <Input
                  name="description"
                  value={description}
                  onChange={handleCalenderChange}
                  type="textarea"
                  className="form-control"
                  id="description"
                />
              </FormGroup> 
              <FormGroup>
                <Label htmlFor="event_type">Event Type</Label>
                <Input
                  name="event_type"
                  value={event_type}
                  onChange={handleCalenderChange}
                  type="select"
                  errorMessage="event_type is required"
                  className="form-control"
                  validate={{ required: { value: true } }}
                  id="event_type"
                >
                  <option hidden>Select</option>
                  <option value="unavailable">Unavailable</option>
                  <option value="temporary_blocked">Temporary Block</option>
                  <option value="booking_confirmed">Confirmed Booking</option>
                </Input>
                <p className='text-danger font-weight-bold'>{eventTypeErrorMsg}</p>
              </FormGroup>
              <ModalFooter>
                <div className='row w-100'>
                  <div className='col-6'>
                    <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={createFilterEvent}
                    >
                      Create
                    </Button>
                  </div>
                  <div className='col-6'>
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => {
                        setIsOpen(false)
                        setCalenderEventData({
                          ...calenderEventData,
                          title: '',
                          description: '',
                          event_type: '',
                        })
                        setEventTypeErrorMsg('')
                      }}
                    >
                      close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      )}

      {showNotificationModal && 
        <div>
          <Modal size="lg" isOpen={showNotificationModal} backdrop="static">
            <ModalHeader>Send Booking Information - {notificationUserName}</ModalHeader>
            <ModalBody>
              <Row className='justify-content-center'>
                <div className='w-50'>
                  <FormGroup>
                    <Label htmlFor="wix_id">Dog parent email</Label>
                    <Input
                      name="wix_id"
                      value={wix_id}
                      onChange={(e) => {setNotificationFormData({ ...notificationFormData, wix_id: e.target.value })}}
                      type="text"
                      className="form-control"
                      id="wix_id"
                    />
                    <Label htmlFor="wix_id">Service Type</Label>
                    <Input
                      name="service_type"
                      value={service_type}
                      onChange={(e) => {setNotificationFormData({ ...notificationFormData, service_type: e.target.value })}}
                      type="select"
                      className="form-control"
                      id="service_type"
                    >
                      <option hidden>Select</option>
                      <option value="overnight_sitter_home">
                      Overnight Boarding Sitter`s Home
                      </option>
                      <option value="overnight_parent_home">
                      Overnight Boarding Dog Parent`s Home
                      </option>
                      <option value="daycare_sitter_home">Daycare Sitter`s Home</option>
                      <option value="daycare_parent_home">
                      Daycare Dog Parent`s Home
                      </option>
                    </Input>
                  </FormGroup>
                </div>
              </Row>
              <ModalFooter>
                <div className='row w-100 justify-content-center'>
                  <div className='col-6 text-center'>
                    <Button
                      className="btn btn-info w-75"
                      type="button"
                      onClick={handleSendNoticationById}
                    >
                      Send
                    </Button>
                  </div>
                  <div className='col-6 text-center'>
                    <Button
                      className="btn btn-danger w-75"
                      type="button"
                      onClick={() => { 
                        setShowNotificationModal(false)
                        setNotificationFormData({ ...notificationFormData, wix_id: '', service_type: '' })
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </ModalBody>
          </Modal>
        </div>
      }
    </>
  )
}

export default AllFilters
