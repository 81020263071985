import { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, Input, Label, Button } from 'reactstrap'
import Table from '../Table'
import { userRole } from 'Utils/helper'
import BookingStatusActions from 'Components/BookingStatusActions'
import CsvDownloadButton from 'react-json-to-csv'

const UpdateBookingDetailsList = ({
  tableData = [],
  tableHeading = [],
  handleTablePageChange,
  handleViewBookingData,
  handleEditBookingData,
  handleSitterBookingData,
  handleDeleteBookingData,
  page,
  sizePerPage,
  totalSize,
  isLoading,
  renderTable,
  handleFilter,
  dataCount,
  searchData,
  handleSearch,
  isCsvLoaded,
  handleCallDataForCsv,
  csvData,
  handleUpdateStatus
}) => {
  const [rowData, setRowData] = useState([])
  const [bookingStatus, setBookingStatus] = useState({
    status: '',
    bookingId: '',
  })
  const [bookingStatusModal, setBookingStatusModal] = useState(false)

  const viewContentFormatter = (cell, row) => {
    return (
      <div
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          gap: '2px',
        }}
        key={row.user_id}
      >
        {['super_admin', 'admin'].includes(userRole()) && (
          <i
            className="ri-eye-line text-info h4 cursor-pointer mr-2"
            onClick={() => handleViewBookingData(row.id)}
          />
        )}
        {['super_admin', 'admin'].includes(userRole()) && (
          <i
            className="ri-edit-2-fill text-warning h4 cursor-pointer mr-2"
            onClick={() => handleEditBookingData(row.id, row.parent_user_id)}
          />
        )}
        {['super_admin', 'admin', 'ops_1'].includes(userRole()) && <i
          className="ri-message-3-fill text-primary h4 cursor-pointer mx-2"
          onClick={() => handleUpdateStatus(row)}
        />}
      </div>
    )
  }

  useEffect(() => {
    tableHeading.push({
      text: 'Actions',
      dataField: 'view',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%', cursor: 'pointer' },
      formatter: viewContentFormatter,
    })
    return () => {
      tableHeading.pop()
    }
  }, [])

  useEffect(() => {
    if (tableData) {
      var result = tableData.map(function(el, i){
        var o = Object.assign({}, el)
        o.sNo = i + 1 + page * 10 - 10
        return o
      })
      setRowData(result)
    }
  }, [tableData])

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Changes Requested</h3>
                    <div
                      className="page-title-right"
                      style={{ display: 'flex', width: '20%', gap: '10px' }}
                    ></div>
                  </div>
                </Col>
                <Col xs={12} className='mb-4'>
                  <Row className="align-items-end">
                    <Col xs={4}>
                      <Label htmlFor="searchSitter">Search</Label>
                      <Input
                        style={{ height: '35px' }}
                        name="searchSitter"
                        value={searchData}
                        onChange={handleSearch}
                        type="text"
                        className="form-control"
                        id="searchSitter"
                      />
                    </Col>
                    <Col xs={1} className="text-center">
                      <Label>Count</Label>
                      <p className="text-success">{dataCount}</p>
                    </Col>
                    {/* <Col xs={1}>
                      <Button
                        type="button"
                        onClick={handleFilter}
                        color="primary"
                        className="waves-effect"
                      >
                        Seacrh
                      </Button>
                    </Col> */}
                    {isCsvLoaded ? (
                      ['super_admin', 'admin'].includes(userRole()) && (
                        <Col xs={1}>
                          <CsvDownloadButton data={csvData} />
                        </Col>
                      )
                    ) : (
                      <Col xs={1}>
                        <Button onClick={handleCallDataForCsv}>
                          <i className="ri-download-2-line"></i>
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
              <Row></Row>
              <Table
                rows={rowData}
                columns={tableHeading}
                onTableChange={handleTablePageChange}
                page={page}
                sizePerPage={sizePerPage}
                totalSize={totalSize}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {bookingStatusModal && (
        <BookingStatusActions
          isOpen={bookingStatusModal}
          renderTable={renderTable}
          statusType={bookingStatus}
          handleClose={() => {setBookingStatusModal(false), localStorage.removeItem('dogsAcceptConsent')}}
        />
      )}
    </>
  )
}

export default UpdateBookingDetailsList
