import { getDogList, updateCareFormInfo } from 'Views/Dashboard/Views/Parents/Api'
import { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col, FormGroup, Label, Input } from 'reactstrap'

const DogCareForm = ({ isOpen, handleClose, dogId, careFormData }) => {

  const [dogCareData, setDogCareData] = useState()

  useEffect(() => {
    setDogCareData(careFormData)
  }, [careFormData])

  const handleInputChange = (e) => {
    setDogCareData({ ...dogCareData,
      [e.target.name]: e.target.value
    })
  }

  const handleUpdateCareForm = () => {
    updateCareFormInfo({
      'dog_info_form': dogCareData
    },dogId).then(() => {
      const getData = localStorage.getItem('dogsAcceptConsent')
      if(getData){
        let storedArray = JSON.parse(localStorage.getItem('dogsAcceptConsent')) || []
        storedArray.push(dogId)
        localStorage.setItem('dogsAcceptConsent', JSON.stringify(storedArray))
      }else{
        localStorage.setItem('dogsAcceptConsent', JSON.stringify([dogId]))
      }
      handleClose()
    })
  }

  return (
    <Modal size="xl" isOpen={isOpen} backdrop="static">
      <ModalHeader>Care Form</ModalHeader>
      <ModalBody>
        <Row>
          <h4>Basic Care</h4>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="kind_of_day_dog_need">1. What kind of day does your dog need?</Label>
              <Input
                type="text"
                name="kind_of_day_dog_need"
                value={dogCareData?.kind_of_day_dog_need}
                onChange={handleInputChange}
                className="form-control"
                id="kind_of_day_dog_need"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="kind_of_day_dog_need_details">1(a) Please share more details</Label>
              <Input
                type="textarea"
                name="kind_of_day_dog_need_details"
                value={dogCareData?.kind_of_day_dog_need_details}
                onChange={handleInputChange}
                className="form-control"
                id="kind_of_day_dog_need_details"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="no_of_meals_in_a_day">How many times does your dog need to be given food in a day?</Label>
              <Input
                type="select"
                name="no_of_meals_in_a_day"
                value={dogCareData?.no_of_meals_in_a_day}
                onChange={handleInputChange}
                className="form-control"
                id="no_of_meals_in_a_day"
              >
                <option hidden>Select</option>
                <option value='once'>Once</option>
                <option value='twice'>Twice</option>
                <option value='thrice'>Thrice</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="meal_additional_info">Please share more details about what your dog should be given for each</Label>
              <Input
                type="textarea"
                name="meal_additional_info"
                value={dogCareData?.meal_additional_info}
                onChange={handleInputChange}
                className="form-control"
                id="meal_additional_info"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="food_to_avoid">Anything specific he/she is not allowed to
                 eat or is allergic to? Please providedetails</Label>
              <Input
                type="textarea"
                name="food_to_avoid"
                value={dogCareData?.food_to_avoid}
                onChange={handleInputChange}
                className="form-control"
                id="food_to_avoid"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="is_dog_on_heat">Will your dog be on heat during the booking?</Label>
              <Input
                type="select"
                name="is_dog_on_heat"
                value={dogCareData?.is_dog_on_heat}
                onChange={handleInputChange}
                className="form-control"
                id="is_dog_on_heat"
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Male so not applicable'>Male so not applicable</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="is_toilet_trained">Is your dog toilet trained?</Label>
              <Input
                type="select"
                name="is_toilet_trained"
                value={dogCareData?.is_toilet_trained}
                onChange={handleInputChange}
                className="form-control"
                id="is_toilet_trained"
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="toilet_habits">Please explain anything else that must be kept in
                 mind about your dog`s toilet habits?</Label> 
              <Input
                type="textarea"
                name="toilet_habits"
                value={dogCareData?.toilet_habits}
                onChange={handleInputChange}
                className="form-control"
                id="toilet_habits"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="no_of_walks_in_a_day">How many walks does your dog need in a day?</Label>
              <Input
                type="select"
                name="no_of_walks_in_a_day"
                value={dogCareData?.no_of_walks_in_a_day}
                onChange={handleInputChange}
                className="form-control"
                id="no_of_walks_in_a_day"
              >
                <option hidden>Select</option>
                <option value='1'>1</option>
                <option value='2'>2</option>
                <option value='3'>3</option>
                <option value='4'>4</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="kind_of_walk_dog_need">2. What kind of walk does your dog need?</Label>
              <Input
                type="select"
                name="kind_of_walk_dog_need"
                value={dogCareData?.kind_of_walk_dog_need}
                onChange={handleInputChange}
                className="form-control"
                id="kind_of_walk_dog_need"
              >
                <option hidden>Select</option>
                <option value='Active Walk'>Active Walk</option>
                <option value='Relaxed Walk'>Relaxed Walk</option>
                <option value='Other'>Other</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="kind_of_walk_dog_need_details">2(a) Please share more details</Label>
              <Input
                type="textarea"
                name="kind_of_walk_dog_need_details"
                value={dogCareData?.kind_of_walk_dog_need_details}
                onChange={handleInputChange}
                className="form-control"
                id="kind_of_walk_dog_need_details"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="walk_duration_info">
                How long should each walk be? Please specify for each walk so that the dogsitter has all the information needed.
              </Label>
              <Input
                type="textarea"
                name="walk_duration_info"
                value={dogCareData?.walk_duration_info}
                onChange={handleInputChange}
                className="form-control"
                id="walk_duration_info"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="walk_additional_info">
              Is there anything the dog sitter needs to be careful about when walking your dog?Please explain.
              </Label>
              <Input
                type="textarea"
                name="walk_additional_info"
                value={dogCareData?.walk_additional_info}
                onChange={handleInputChange}
                className="form-control"
                id="walk_additional_info"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="trained_walk_on_leash">
              Is your dog trained to walk on the leash? Please explain
              </Label>
              <Input
                type="textarea"
                name="trained_walk_on_leash"
                value={dogCareData?.trained_walk_on_leash}
                onChange={handleInputChange}
                className="form-control"
                id="trained_walk_on_leash"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <h4>Knowing Your Dog Better</h4>

          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="is_dog_allowed_on_sofa">Is your dog allowed on the sofas at home?</Label>
              <Input
                type="select"
                name="is_dog_allowed_on_sofa"
                value={dogCareData?.is_dog_allowed_on_sofa}
                onChange={handleInputChange}
                className="form-control"
                id="is_dog_allowed_on_sofa"
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Sometimes'>Sometimes</option>
                <option value='Yes but please don`t allow'>Yes but please don`t allow</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="is_dog_used_to_sleep_on_bed">
              Is your dog used to sleeping on the bed?
              </Label>
              <Input
                type="textarea"
                name="is_dog_used_to_sleep_on_bed"
                value={dogCareData?.is_dog_used_to_sleep_on_bed}
                onChange={handleInputChange}
                className="form-control"
                id="is_dog_used_to_sleep_on_bed"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="is_dog_used_to_sleep_in_same_room">
              Is your dog used to sleeping in the same room?
              </Label>
              <Input
                type="textarea"
                name="is_dog_used_to_sleep_in_same_room"
                value={dogCareData?.is_dog_used_to_sleep_in_same_room}
                onChange={handleInputChange}
                className="form-control"
                id="is_dog_used_to_sleep_in_same_room"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="anything_to_hide_from_dog">
              Is there anything that needs to be put away or kept hidden when your dog isalone at home?
              </Label>
              <Input
                type="textarea"
                name="anything_to_hide_from_dog"
                value={dogCareData?.anything_to_hide_from_dog}
                onChange={handleInputChange}
                className="form-control"
                id="anything_to_hide_from_dog"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="remove_dog_collar_at_home">Do you remove your dog`s collar at home?</Label>
              <Input
                type="select"
                name="remove_dog_collar_at_home"
                value={dogCareData?.remove_dog_collar_at_home}
                onChange={handleInputChange}
                className="form-control"
                id="remove_dog_collar_at_home"
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="dog_damages_things">
              Does your dog chew on, scratch or otherwise damage furniture, electronics or other household objects?
              </Label>
              <Input
                type="textarea"
                name="dog_damages_things"
                value={dogCareData?.dog_damages_things}
                onChange={handleInputChange}
                className="form-control"
                id="dog_damages_things"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="get_along_with_other_dogs">Does your dog get along with other dogs?</Label>
              <Input
                type="select"
                name="get_along_with_other_dogs"
                value={dogCareData?.get_along_with_other_dogs}
                onChange={handleInputChange}
                className="form-control"
                id="get_along_with_other_dogs"
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="anything_dog_not_good_in">
              Are there any specific situations he/she is good/not good in? Please explain
              </Label>
              <Input
                type="textarea"
                name="anything_dog_not_good_in"
                value={dogCareData?.anything_dog_not_good_in}
                onChange={handleInputChange}
                className="form-control"
                id="anything_dog_not_good_in"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="comfortable_with_cats">Is your dog comfortable with cats?</Label>
              <Input
                type="select"
                name="comfortable_with_cats"
                value={dogCareData?.comfortable_with_cats}
                onChange={handleInputChange}
                className="form-control"
                id="comfortable_with_cats"
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Maybe'>Maybe</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="comfortable_with_cats_info">
              Please explain
              </Label>
              <Input
                type="textarea"
                name="comfortable_with_cats_info"
                value={dogCareData?.comfortable_with_cats_info}
                onChange={handleInputChange}
                className="form-control"
                id="comfortable_with_cats_info"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="react_with_strangers">
              How does your dog usually react to strangers? Please explain
              </Label>
              <Input
                type="textarea"
                name="react_with_strangers"
                value={dogCareData?.react_with_strangers}
                onChange={handleInputChange}
                className="form-control"
                id="react_with_strangers"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="things_to_avoid_when_around_people">
              Is there anything specific that should be avoided when your dog is around people?
              </Label>
              <Input
                type="textarea"
                name="things_to_avoid_when_around_people"
                value={dogCareData?.things_to_avoid_when_around_people}
                onChange={handleInputChange}
                className="form-control"
                id="things_to_avoid_when_around_people"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="is_possessive_with_toys_or_food">
              Is your dog possessive about his/her toys or food?
              </Label>
              <Input
                type="textarea"
                name="is_possessive_with_toys_or_food"
                value={dogCareData?.is_possessive_with_toys_or_food}
                onChange={handleInputChange}
                className="form-control"
                id="is_possessive_with_toys_or_food"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="is_aggressive_about_anything">
              Does he/she get aggressive about anything?
              </Label>
              <Input
                type="textarea"
                name="is_aggressive_about_anything"
                value={dogCareData?.is_aggressive_about_anything}
                onChange={handleInputChange}
                className="form-control"
                id="is_aggressive_about_anything"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="common_words_or_signals">
              Please share a list of the common words/verbal signals your dog understands inwhichever language
               you use so that the dog sitter is aware of these. Please explain if required.
              </Label>
              <Input
                type="textarea"
                name="common_words_or_signals"
                value={dogCareData?.common_words_or_signals}
                onChange={handleInputChange}
                className="form-control"
                id="common_words_or_signals"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="comfortable_travelling_in_car">
              Is your dog comfortable travelling by car?
              </Label>
              <Input
                type="textarea"
                name="comfortable_travelling_in_car"
                value={dogCareData?.comfortable_travelling_in_car}
                onChange={handleInputChange}
                className="form-control"
                id="comfortable_travelling_in_car"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="special_preparation_to_travel_in_car">
              Is there any special preparation needed for him/ her to travel by car? Please explain 
              </Label>
              <Input
                type="textarea"
                name="special_preparation_to_travel_in_car"
                value={dogCareData?.special_preparation_to_travel_in_car}
                onChange={handleInputChange}
                className="form-control"
                id="special_preparation_to_travel_in_car"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="any_habit_or_pattern_of_dog">
              Are there any habits or patterns of your dog which the dog sitter should know about?
              </Label>
              <Input
                type="textarea"
                name="any_habit_or_pattern_of_dog"
                value={dogCareData?.any_habit_or_pattern_of_dog}
                onChange={handleInputChange}
                className="form-control"
                id="any_habit_or_pattern_of_dog"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="no_of_updates_about_dog_in_a_day">
                How many times a day do you want to be updated about your dog? </Label>
              <Input
                type="select"
                name="no_of_updates_about_dog_in_a_day"
                value={dogCareData?.no_of_updates_about_dog_in_a_day}
                onChange={handleInputChange}
                className="form-control"
                id="no_of_updates_about_dog_in_a_day"
              >
                <option hidden>Select</option>
                <option value='once'>Once</option>
                <option value='twice'>Twice</option>
                <option value='thrice'>Thrice</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="address_info_to_leave_dog_if_not_available">
              How would you prefer to be updated? Please explain and share relevant details like number etc. 
              </Label>
              <Input
                type="textarea"
                name="update_reference"
                value={dogCareData?.update_reference}
                onChange={handleInputChange}
                className="form-control"
                id="update_reference"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="address_info_to_leave_dog_if_not_available">
              If you are not able to pick up your dog and have not informed us, please share the name, telephone
               number and address of an alternate guardian your dog can be handed over to 
              </Label>
              <Input
                type="textarea"
                name="address_info_to_leave_dog_if_not_available"
                value={dogCareData?.address_info_to_leave_dog_if_not_available}
                onChange={handleInputChange}
                className="form-control"
                id="address_info_to_leave_dog_if_not_available"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="emergency_details">
              Please share the name, telephone number and address of someone we can contact in
               case we cannot reach you in an emergency
              </Label>
              <Input
                type="textarea"
                name="emergency_details"
                value={dogCareData?.emergency_details}
                onChange={handleInputChange}
                className="form-control"
                id="emergency_details"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <h4>Medical Information</h4>

          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="vaccination_up_to_date">
              Will your dog`s vaccinations be up to date at the time of the booking? </Label>
              <Input
                type="select"
                name="vaccination_up_to_date"
                value={dogCareData?.vaccination_up_to_date}
                onChange={handleInputChange}
                className="form-control"
                id="vaccination_up_to_date"
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Other'>Other</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="vaccination_additional_info">
              If other, please explain
              </Label>
              <Input
                type="textarea"
                name="vaccination_additional_info"
                value={dogCareData?.vaccination_additional_info}
                onChange={handleInputChange}
                className="form-control"
                id="vaccination_additional_info"
              />
            </FormGroup>
          </Col>

          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="any_medication_to_dog">
              Does your dog need to be given any medication while you are away? </Label>
              <Input
                type="select"
                name="any_medication_to_dog"
                value={dogCareData?.any_medication_to_dog}
                onChange={handleInputChange}
                className="form-control"
                id="any_medication_to_dog"
              >
                <option hidden>Select</option>
                <option value='Yes'>Yes</option>
                <option value='No'>No</option>
                <option value='Other'>Other</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="medication_additional_info">
              If other, please explain
              </Label>
              <Input
                type="textarea"
                name="medication_additional_info"
                value={dogCareData?.medication_additional_info}
                onChange={handleInputChange}
                className="form-control"
                id="medication_additional_info"
              />
            </FormGroup>
          </Col>

          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="any_health_issues">
              Has your dog had any previous health problems or medical conditions? Please explain
              </Label>
              <Input
                type="textarea"
                name="any_health_issues"
                value={dogCareData?.any_health_issues}
                onChange={handleInputChange}
                className="form-control"
                id="any_health_issues"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="preferred_veterinary_doctor">
              Please share details of your preferred veterinary doctor in case of a situation where a vet is needed
              </Label>
              <Input
                type="textarea"
                name="preferred_veterinary_doctor"
                value={dogCareData?.preferred_veterinary_doctor}
                onChange={handleInputChange}
                className="form-control"
                id="preferred_veterinary_doctor"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="upper_limit_to_spend_on_medication">
              In case of an emergency situation, what is the upper limit you authorise the dog sitter to spend?
              </Label>
              <Input
                type="textarea"
                name="upper_limit_to_spend_on_medication"
                value={dogCareData?.upper_limit_to_spend_on_medication}
                onChange={handleInputChange}
                className="form-control"
                id="upper_limit_to_spend_on_medication"
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label htmlFor="any_medical_insurance">
              Is your dog covered by a specific medical insurance? Please share details
              </Label>
              <Input
                type="textarea"
                name="any_medical_insurance"
                value={dogCareData?.any_medical_insurance}
                onChange={handleInputChange}
                className="form-control"
                id="any_medical_insurance"
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={handleUpdateCareForm}
          color="primary"
          className="waves-effect"
        >
          Update
        </Button>
        <Button
          type="button"
          onClick={handleClose}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DogCareForm